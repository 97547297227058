import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

const Home = React.lazy(() => import ('../Container/home'));
const Login = React.lazy(() => import ('../Container/login'));
const Signup = React.lazy(() => import ('../Container/signup'));
const ForgotPassword = React.lazy(() => import ('../Container/forgot-password'));
const ResourceMarketplace = React.lazy(() => import ('../Container/resource-marketplace'));
const MyRoutes = () => {
  useEffect(() => {
  //  console.log('knlk', Home) 
  }, []);
  return(
    <Routes>
      <Route path="/" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <Home />
        </React.Suspense>   
        } 
      />
      <Route path="/login" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <Login />
        </React.Suspense>   
        } 
      />
      <Route path="/signup" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <Signup />
        </React.Suspense>   
        } 
      />
      <Route path="/forgot-password" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <ForgotPassword />
        </React.Suspense>   
        } 
      />
      <Route path="/resource-marketplace" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <ResourceMarketplace />
        </React.Suspense>   
        } 
      />
    </Routes>
  )
}

export default MyRoutes